import { Link } from "gatsby"

type Props = {
  articleId: string;
  titleForCard: GatsbyTypes.Maybe<string>
  createdAt: GatsbyTypes.Maybe<string>
  thumbnailUrl: GatsbyTypes.Maybe<string>;
  tags: GatsbyTypes.Maybe<readonly GatsbyTypes.Maybe<Pick<GatsbyTypes.ContentfulTag, "contentful_id" | "name">>[]>;
}

const ArticleCard = ({
  articleId,
  titleForCard,
  createdAt,
  thumbnailUrl,
  tags,
}: Props) => {
  return (
    <div className="shadow-lg bg-white">
      <Link to={`/articles/${articleId}/`} className="group">
        <figure>
          <img className="group-hover:opacity-75 transition-opacity" src={thumbnailUrl} alt="post-cover" />
        </figure>
        <div className="text-sm text-gray-500 pt-4 pb-2 px-4">{createdAt}</div>
        <div className="leading-8 h-16 font-bold text-lg text-gray-800 px-4 group-hover:opacity-75 transition-opacity">{titleForCard}</div>
      </Link>
      <div className="p-4 flex space-x-1 text-gray-500 text-sm">
        {tags?.map(tag =>
          <Link key={tag?.contentful_id} to={`/tags/${tag?.contentful_id}`} className="border py-1 px-2 hover:border-gray-400 transition-all">
            {tag?.name}
          </Link>
        )}
      </div>
    </div>
  )
}
export default ArticleCard
import Layout from "../components/layout"
import ArticleCard from "../components/article-card"
import { graphql } from "gatsby"
import { useLocation } from "@reach/router"
import Seo from "../components/seo"
import SiteImage from "../../static/logo.png"
import '../__generated__/gatsby-types'

type Props = {
  data: GatsbyTypes.ArticlesQuery
}

const Home = ({ data }: Props) => {
  const contents = data.allContentfulArticles.edges
  const location = useLocation()
  const siteImageUrl = `${process.env.GATSBY_SITE_ORIGIN}${SiteImage}`

  return (
    <Layout pageType="top">
      <Seo title="uuuno" url={location.href} image={siteImageUrl} description="個人的に日々学んだこと、感じたことを書いています。" />
      <main className="pt-24 mx-auto lg:max-w-screen-lg md:max-w-screen-md sm:max-w-screen-sm">
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {contents.map(({node}) => {
            return (
              <ArticleCard
                key={node.id}
                articleId={node.id}
                titleForCard={node.titleForCard}
                createdAt={node.createdAt}
                thumbnailUrl={node.thumbnail?.file?.url}
                tags={node.metadata?.tags}
              />
            )
          }
          )}
        </div>
      </main>
    </Layout>
  )
}

export default Home

export const query = graphql`
  query Articles {
    allContentfulArticles(sort: {fields: createdAt, order: DESC}) {
      edges {
        node {
          id
          title
          titleForCard
          thumbnail {
            title
            file {
              url
            }
          }
          description {
            description
          }
          metadata {
            tags {
              contentful_id
              name
            }
          }
          createdAt(locale: "ja-JP", formatString: "YYYY年MM月DD日")
        }
      }
    }
  }
`